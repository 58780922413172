import { useAuth } from "../../contexts/AuthContext";
import { Notification } from 'rsuite';

export default function ErrorMessage() {
    const { error, setError } = useAuth();

    return (
        error && (
            <Notification closable type="error" header="We have a little problem." onClose={() => setError("")}>
                {error}
            </Notification>
        )
    );
}