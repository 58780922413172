import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container, Header as HeaderRS, Content, Footer } from 'rsuite';
import { AuthProvider } from "./contexts/AuthContext";

import ErrorMessage from "./components/layouts/ErrorMessage";
import WithPrivateRoute from "./utils/WithPrivateRoute";
import Register from "./components/accounts/Register";
import Login from "./components/accounts/Login";
import Profile from "./components/accounts/Profile";
import Header from "./components/layouts/Header";

import Members from "./components/pages/Members/Members";
import Parameters from "./components/pages/Parameters/Parameters";

import "./App.css";
import 'rsuite/dist/rsuite.min.css';

function App() {
    return (
        <AuthProvider>
            {/* <Routes>
                <Route path="/" element={<Frame navs={appNavs} />}>
                    <Route index element={<DashboardPage />} />
                    <Route path="dashboard" element={<DashboardPage />} />
                    <Route path="table-members" element={<MembersPage />} />
                    <Route path="table-virtualized" element={<VirtualizedTablePage />} />
                    <Route path="error-404" element={<Error404Page />} />
                    <Route path="error-403" element={<Error403Page />} />
                    <Route path="error-500" element={<Error500Page />} />
                    <Route path="error-503" element={<Error503Page />} />
                    <Route path="sign-in" element={<SignInPage />} />
                    <Route path="sign-up" element={<SignUpPage />} />
                    <Route path="form-basic" element={<FormBasicPage />} />
                    <Route path="form-wizard" element={<FormWizardPage />} />
                    <Route path="calendar" element={<CalendarPage />} />
                </Route>
                <Route path="*" element={<Error404Page />} />
            </Routes> */}
            <Router>
                <Container>
                    <HeaderRS>
                        <Header />
                    </HeaderRS>
                    <Content>
                        <ErrorMessage />
                        <Routes>
                            <Route exact path="/register" element={<Register />} />
                            <Route exact path="/login" element={<Login />} />
                            <Route
                                exact
                                path="/profile"
                                element={
                                    <WithPrivateRoute>
                                        <Profile />
                                    </WithPrivateRoute>
                                } />
                            <Route
                                exact
                                path="/"
                                element={
                                    <WithPrivateRoute>
                                        <Members />
                                    </WithPrivateRoute>
                                } />
                            <Route
                                exact
                                path="/parametri"
                                element={
                                    <WithPrivateRoute>
                                        <Parameters />
                                    </WithPrivateRoute>
                                } />
                        </Routes>
                    </Content>
                    <Footer>

                    </Footer>
                </Container>
                
                
            </Router>
        </AuthProvider>
    );
}

export default App;