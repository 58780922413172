import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";
import { generateAvatar } from "../../utils/GenerateAvatar";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Profile() {
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [avatars, setAvatars] = useState([]);
    const [selectedAvatar, setSelectedAvatar] = useState();
    const [loading, setLoading] = useState(false);

    const { currentUser, updateUserProfile, setError } = useAuth();

    useEffect(() => {
        const fetchData = () => {
            const res = generateAvatar();
            setAvatars(res);
        };

        fetchData();
    }, []);

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (selectedAvatar === undefined) {
            return setError("Please select an avatar");
        }

        try {
            setError("");
            setLoading(true);
            const user = currentUser;
            const profile = {
                displayName: username,
                photoURL: avatars[selectedAvatar],
            };
            await updateUserProfile(user, profile);
            navigate("/");
        } catch (e) {
            setError("Failed to update profile");
        }

        setLoading(false);
    };

    return (
        <div className="">
            <div className="">
                <div className="">
                    <h2 className="m">
                        Pick an avatar
                    </h2>
                </div>
                <form className="" onSubmit={handleFormSubmit}>
                    <div className="" style={{display: "flex", flexDirection: "row"}}>
                        {avatars.map((avatar, index) => (
                        <div key={index} className="">
                            <div className="">
                                <img
                                    style={{width: "100px"}}
                                    alt="gallery"
                                    className={classNames(
                                    index === selectedAvatar
                                        ? "border-4  border-blue-700 dark:border-blue-700"
                                        : "cursor-pointer hover:border-4 hover:border-blue-700",
                                    "block object-cover object-center w-36 h-36 rounded-full"
                                    )}
                                    src={avatar}
                                    onClick={() => setSelectedAvatar(index)}
                                />
                            </div>
                        </div>
                        ))}
                    </div>

                    <div className="">
                        <input
                            id="username"
                            name="username"
                            type="text"
                            autoComplete="username"
                            required
                            className=""
                            placeholder="Enter a Display Name"
                            defaultValue={currentUser.displayName && currentUser.displayName}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div>
                        <button
                            type="submit"
                            disabled={loading}
                            className=""
                        >
                            Update Profile
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}