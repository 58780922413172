import React, {useState, useEffect} from 'react';
import auth from "../../../config/firebase";
import { Drawer, Table, ButtonToolbar, Button, IconButton, FlexboxGrid, Notification, useToaster, Loader } from 'rsuite';

import PlusIcon from '@rsuite/icons/Plus';
import TrashIcon from '@rsuite/icons/Trash';
import EditIcon from '@rsuite/icons/Edit';
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';

const { Column, HeaderCell, Cell } = Table;

  
const ActionCell = ({ rowData, dataKey, onClick, handleDelete, handleUpdate, ...props }) => {
	return (
		<Cell {...props} style={{ padding: '6px' }}>
			{rowData.status === 'EDIT' ?
				<ButtonToolbar>
					<IconButton
						appearance="primary"
						color="green"
						size="xs"
						icon={<CheckOutlineIcon />}
						onClick={() => {
							handleUpdate(rowData.id);
							onClick(rowData.id);
						}}
					>
						Shrani
					</IconButton>
					<IconButton
						appearance="primary"
						color="red"
						size="xs"
						icon={<TrashIcon />}
						onClick={() => {
							handleDelete(rowData.id)
						}}
					>
						Izbriši
					</IconButton>
				</ButtonToolbar>
				:
				<IconButton
					appearance="primary"
					color="cyan"
					size="xs"
					icon={<EditIcon />}
					onClick={() => {
						onClick(rowData.id);
					}}
				>
					Uredi
				</IconButton>
			}
		</Cell>
	);
};

const Parameters = () => {
	const [open, setOpen] = React.useState(false);
	const [openPodrocje, setOpenPodrocje] = React.useState(false);
	const [openVloga, setOpenVloga] = React.useState(false);
	const [loading, setLoading] = useState(true);
	const [dataLanguage, setDataLanguage] = React.useState([]);
	const [dataPodrocja, setDataPodrocja] = React.useState([]);
	const [dataVloga, setDataVloga] = React.useState([]);
	const [newParameter, setNewParameter] = React.useState("");
	const [responseData, setResponseData] = useState(null);
	const toaster = useToaster();

	const messageSuccess = (
		<Notification type="success" header="Uspešno" closable>
			<p>Shranjevanje uspešno.</p>
		</Notification>
	);
	const messageFailed = (
		<Notification type="error" header="Napaka" closable>
			<p>Pri shranjevanju smo naleteli na napako.</p>
		</Notification>
	);
	const messageUpdateSuccess = (
		<Notification type="success" header="Uspešno" closable>
			<p>Parameter uspešno posodobljen.</p>
		</Notification>
	);
	const messageUpdateFailed = (
		<Notification type="error" header="Napaka" closable>
			<p>Pri urejanju smo naleteli na napako.</p>
		</Notification>
	);
	const messageDeleteSuccess = (
		<Notification type="success" header="Uspešno" closable>
			<p>Brisanje uspešno.</p>
		</Notification>
	);
	const messageDeleteFailed = (
		<Notification type="error" header="Napaka" closable>
			<p>Pri brisanju smo naleteli na napako.</p>
		</Notification>
	);

	const handleChange = (id, key, value) => {
		const nextData = Object.assign([], dataLanguage);
		nextData.find(item => item.id === id)[key] = value;
		setDataLanguage(nextData);
	};
	const handleEditState = id => {
		const nextData = Object.assign([], dataLanguage);
		const activeItem = nextData.find(item => item.id === id);
		activeItem.status = activeItem.status ? null : 'EDIT';
		setDataLanguage(nextData);
	};

	const handleChangePodrocje = (id, key, value) => {
		const nextData = Object.assign([], dataPodrocja);
		nextData.find(item => item.id === id)[key] = value;
		setDataPodrocja(nextData);
	};
	const handleEditStatePodrocje = id => {
		const nextData = Object.assign([], dataPodrocja);
		const activeItem = nextData.find(item => item.id === id);
		activeItem.status = activeItem.status ? null : 'EDIT';
		setDataPodrocja(nextData);
	};

	const handleChangeVloga = (id, key, value) => {
		const nextData = Object.assign([], dataVloga);
		nextData.find(item => item.id === id)[key] = value;
		setDataVloga(nextData);
	};
	const handleEditStateVloga = id => {
		const nextData = Object.assign([], dataVloga);
		const activeItem = nextData.find(item => item.id === id);
		activeItem.status = activeItem.status ? null : 'EDIT';
		setDataVloga(nextData);
	};

	const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
		const editing = rowData.status === 'EDIT';
		// console.log(rowData, dataKey)
		return (
			<Cell {...props} className={editing ? 'table-content-editing' : ''}>
				{editing ? (
				<input
					className="rs-input"
					defaultValue={rowData[dataKey]}
					style={{height: "100%"}}
					onChange={event => {
						onChange && onChange(rowData.id, dataKey, event.target.value);
					}}
				/>
				) : (
				<span className="table-content-edit-span">{rowData[dataKey]}</span>
				)}
			</Cell>
		);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const user = auth.currentUser;
				const token = user && (await user.getIdToken());
		
				const payloadHeader = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				};
				const response = await fetch("https://urejevalnik.dpoint.dev/express/api/v1/jezik/", payloadHeader)
					.then(response => response.json())
					.then((response) => { setDataLanguage(response); });
				const response2 = await fetch("https://urejevalnik.dpoint.dev/express/api/v1/podrocje/", payloadHeader)
					.then(response2 => response2.json())
					.then((response2) => { setDataPodrocja(response2); });
				const response3 = await fetch("https://urejevalnik.dpoint.dev/express/api/v1/vloga/", payloadHeader)
					.then(response3 => response3.json())
					.then((response3) => { setDataVloga(response3); });
				setLoading(false);
			} catch (e) {
				console.log(e);
			}
		};

		fetchData();
	}, []);

	const handleSubmitLanguage = async (e) => {
		e.preventDefault();
		const url = 'https://urejevalnik.dpoint.dev/express/api/v1/jezik/';
    
        try {
			const user = auth.currentUser;
			const token = user && (await user.getIdToken());
	
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({jezik: newParameter}),
			});
		
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
		
			const jsonResponse = await response.json();
			setResponseData(jsonResponse);
			setDataLanguage([...dataLanguage, {jezik: newParameter}])
			setNewParameter("");
			toaster.push(messageSuccess, { placement: "topStart" })
			setOpen(false);
		} catch (error) {
			console.error('Error making POST request:', error);
			toaster.push(messageFailed, { placement: "topStart" })
		}
	}

	const handleUpdateLanguage = async (id) => {
		const updatedParameter = dataLanguage.find(x => x.id === id).jezik;

        try {
			const user = auth.currentUser;
			const token = user && (await user.getIdToken());
	
			const response = await fetch(`https://urejevalnik.dpoint.dev/express/api/v1/jezik/${id}`, {
				method: 'PUT',
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({jezik: updatedParameter}),
			});
		
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
		
			const jsonResponse = await response.json();
			setResponseData(jsonResponse);
			setNewParameter("");
			toaster.push(messageUpdateSuccess, { placement: "topStart" })
			setOpen(false);
		} catch (error) {
			console.error('Error making POST request:', error);
			toaster.push(messageUpdateFailed, { placement: "topStart" })
		}
	}

	const handleDeleteLanguage = async (id) => {

        try {
			const user = auth.currentUser;
			const token = user && (await user.getIdToken());
	
			const response = await fetch(`https://urejevalnik.dpoint.dev/express/api/v1/jezik/${id}`, {
				method: 'DELETE',
				headers: {
					// "Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			});
		
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			} else {
				toaster.push(messageDeleteSuccess, { placement: "topStart" });
				const updatedArray = dataLanguage.filter((obj) => obj.id !== id);
    			setDataLanguage(updatedArray);
			}
		
			// const jsonResponse = await response.json();
			// setResponseData(jsonResponse);
			// toaster.push(messageSuccess, { placement: "topStart" })
		} catch (error) {
			console.error('Error making DELETE request:', error);
			toaster.push(messageDeleteFailed, { placement: "topStart" })
		}
	}

	const handleSubmitPodrocje = async (e) => {
		e.preventDefault();
		const url = 'https://urejevalnik.dpoint.dev/express/api/v1/podrocje/';
    
        try {
			const user = auth.currentUser;
			const token = user && (await user.getIdToken());
	
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({podrocje: newParameter}),
			});
		
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
		
			const jsonResponse = await response.json();
			setResponseData(jsonResponse);
			setDataPodrocja([...dataPodrocja, {jezik: newParameter}])
			setNewParameter("");
			toaster.push(messageSuccess, { placement: "topStart" })
			setOpenPodrocje(false);
		} catch (error) {
			console.error('Error making POST request:', error);
			toaster.push(messageFailed, { placement: "topStart" })
		}
	}

	const handleUpdatePodrocje = async (id) => {
		const updatedParameter = dataPodrocja.find(x => x.id === id).podrocje;

        try {
			const user = auth.currentUser;
			const token = user && (await user.getIdToken());
	
			const response = await fetch(`https://urejevalnik.dpoint.dev/express/api/v1/podrocje/${id}`, {
				method: 'PUT',
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({podrocje: updatedParameter}),
			});
		
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
		
			const jsonResponse = await response.json();
			setResponseData(jsonResponse);
			setNewParameter("");
			toaster.push(messageUpdateSuccess, { placement: "topStart" })
			setOpenPodrocje(false);
		} catch (error) {
			console.error('Error making POST request:', error);
			toaster.push(messageUpdateFailed, { placement: "topStart" })
		}
	}

	const handleDeletePodrocje = async (id) => {

        try {
			const user = auth.currentUser;
			const token = user && (await user.getIdToken());
	
			const response = await fetch(`https://urejevalnik.dpoint.dev/express/api/v1/podrocje/${id}`, {
				method: 'DELETE',
				headers: {
					// "Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			});
		
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			} else {
				toaster.push(messageDeleteSuccess, { placement: "topStart" });
				const updatedArray = dataPodrocja.filter((obj) => obj.id !== id);
    			setDataPodrocja(updatedArray);
			}
		} catch (error) {
			console.error('Error making DELETE request:', error);
			toaster.push(messageDeleteFailed, { placement: "topStart" })
		}
	}

	const handleSubmitVloga = async (e) => {
		e.preventDefault();
		const url = 'https://urejevalnik.dpoint.dev/express/api/v1/vloga/';
    
        try {
			const user = auth.currentUser;
			const token = user && (await user.getIdToken());

			// console.log(JSON.stringify(newParameter))
	
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(newParameter),
			});
		
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
		
			const jsonResponse = await response.json();
			setResponseData(jsonResponse);
			setDataVloga([...dataVloga, {jezik: newParameter}])
			setNewParameter("");
			toaster.push(messageSuccess, { placement: "topStart" })
			setOpenVloga(false);
		} catch (error) {
			console.error('Error making POST request:', error);
			toaster.push(messageFailed, { placement: "topStart" })
		}
	}

	const handleUpdateVloga = async (id) => {
		const updatedParameter = dataVloga.find(x => x.id === id);

        try {
			const user = auth.currentUser;
			const token = user && (await user.getIdToken());
	
			const response = await fetch(`https://urejevalnik.dpoint.dev/express/api/v1/vloga/${id}`, {
				method: 'PUT',
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(updatedParameter),
			});
		
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
		
			const jsonResponse = await response.json();
			setResponseData(jsonResponse);
			setNewParameter("");
			toaster.push(messageUpdateSuccess, { placement: "topStart" })
			setOpenVloga(false);
		} catch (error) {
			console.error('Error making POST request:', error);
			toaster.push(messageUpdateFailed, { placement: "topStart" })
		}
	}

	const handleDeleteVloga = async (id) => {

        try {
			const user = auth.currentUser;
			const token = user && (await user.getIdToken());
	
			const response = await fetch(`https://urejevalnik.dpoint.dev/express/api/v1/vloga/${id}`, {
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
		
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			} else {
				toaster.push(messageDeleteSuccess, { placement: "topStart" });
				const updatedArray = dataVloga.filter((obj) => obj.id !== id);
    			setDataVloga(updatedArray);
			}
		} catch (error) {
			console.error('Error making DELETE request:', error);
			toaster.push(messageDeleteFailed, { placement: "topStart" })
		}
	}

	return (
		<div>
			{loading ? 
			<FlexboxGrid justify='center'>
				<FlexboxGrid.Item colspan={12}>
					<Loader size="lg" content="Nalagam ..." />
				</FlexboxGrid.Item>
			</FlexboxGrid> :
			<div>
				<FlexboxGrid justify='center'>
					<FlexboxGrid.Item colspan={12}>
						<h2>Jeziki</h2>
						<Table height={350} data={dataLanguage}>
							<Column width={200}>
								<HeaderCell>Jezik</HeaderCell>
								<EditableCell dataKey="jezik" onChange={handleChange} />
							</Column>
							<Column width={200}>
								<HeaderCell>...</HeaderCell>
								<ActionCell dataKey="id" handleDelete={handleDeleteLanguage} handleUpdate={handleUpdateLanguage} onClick={handleEditState} />
							</Column>
						</Table>
						<IconButton appearance="primary" color="green" icon={<PlusIcon />} onClick={() => setOpen(true)}>Dodaj jezik</IconButton>
					</FlexboxGrid.Item>
				</FlexboxGrid>
				<FlexboxGrid justify='center' style={{marginTop: "2rem"}}>
					<FlexboxGrid.Item colspan={12}>
						<h2>Strokovna področja</h2>
						<Table height={350} data={dataPodrocja}>
							<Column width={200}>
								<HeaderCell>Strokovno podrocje</HeaderCell>
								<EditableCell dataKey="podrocje" onChange={handleChangePodrocje} />
							</Column>
							<Column width={200}>
								<HeaderCell>...</HeaderCell>
								<ActionCell dataKey="id" handleDelete={handleDeletePodrocje} handleUpdate={handleUpdatePodrocje} onClick={handleEditStatePodrocje} />
							</Column>
						</Table>
						<IconButton appearance="primary" color="green" icon={<PlusIcon />} onClick={() => setOpenPodrocje(true)}>Dodaj strokovno področje</IconButton>
					</FlexboxGrid.Item>
				</FlexboxGrid>
				<FlexboxGrid justify='center' style={{marginTop: "2rem"}}>
					<FlexboxGrid.Item colspan={18}>
						<h2>Vloge</h2>
						<Table data={dataVloga}>
							<Column width={150}>
								<HeaderCell>Vloga</HeaderCell>
								<EditableCell dataKey="vloga" onChange={handleChangeVloga} />
							</Column>
							<Column width={150}>
								<HeaderCell>Izpis v ženski obliki</HeaderCell>
								<EditableCell dataKey="izpis_zenska" onChange={handleChangeVloga} />
							</Column>
							<Column width={150}>
								<HeaderCell>Izpis v moški obliki</HeaderCell>
								<EditableCell dataKey="izpis_moski" onChange={handleChangeVloga} />
							</Column>
							<Column width={200}>
								<HeaderCell>...</HeaderCell>
								<ActionCell dataKey="id" handleDelete={handleDeleteVloga} handleUpdate={handleUpdateVloga} onClick={handleEditStateVloga} />
							</Column>
						</Table>
						<IconButton appearance="primary" color="green" icon={<PlusIcon />} onClick={() => setOpenVloga(true)}>Dodaj vlogo</IconButton>
					</FlexboxGrid.Item>
				</FlexboxGrid>
				

				<Drawer backdrop="static" open={open} onClose={() => {setOpen(false);setNewParameter("");}}>
					<Drawer.Header>
						<Drawer.Title>Dodaj nov jezik</Drawer.Title>
						<Drawer.Actions>
							<Button onClick={() => {setOpen(false);setNewParameter("");}}>Prekliči</Button>
						</Drawer.Actions>
					</Drawer.Header>
					<Drawer.Body>
						<FlexboxGrid justify="center">
							<FlexboxGrid.Item colspan={24}>
								<form className='rs-form rs-form-vertical rs-form-fluid' onSubmit={handleSubmitLanguage}>
									<div className='rs-form-group'>
										<label id="email-address-control-label" htmlFor="language" className="rs-form-control-label">Jezik:</label>
										<div className='rs-form-control rs-form-control-wrapper'>
											<input
												className="rs-input"
												id="language"
												name="language"
												type="text"
												required
												onChange={(e) => setNewParameter(e.target.value)}
											/>
										</div>
									</div>
									<ButtonToolbar>
										<Button appearance="primary" color="green" type="submit">Shrani</Button>
									</ButtonToolbar>
								</form>
							</FlexboxGrid.Item>
						</FlexboxGrid>
					</Drawer.Body>
				</Drawer>
				<Drawer backdrop="static" open={openPodrocje} onClose={() => {setOpenPodrocje(false);setNewParameter("");}}>
					<Drawer.Header>
						<Drawer.Title>Dodaj novo strokovno področje</Drawer.Title>
						<Drawer.Actions>
							<Button onClick={() => {setOpenPodrocje(false);setNewParameter("");}}>Prekliči</Button>
						</Drawer.Actions>
					</Drawer.Header>
					<Drawer.Body>
						<FlexboxGrid justify="center">
							<FlexboxGrid.Item colspan={24}>
								<form className='rs-form rs-form-vertical rs-form-fluid' onSubmit={handleSubmitPodrocje}>
									<div className='rs-form-group'>
										<label id="email-address-control-label" htmlFor="strokovnoPodrocje" className="rs-form-control-label">Strokovno področje:</label>
										<div className='rs-form-control rs-form-control-wrapper'>
											<input
												className="rs-input"
												id="strokovnoPodrocje"
												name="strokovnoPodrocje"
												type="text"
												required
												onChange={(e) => setNewParameter(e.target.value)}
											/>
										</div>
									</div>
									<ButtonToolbar>
										<Button appearance="primary" color="green" type="submit">Shrani</Button>
									</ButtonToolbar>
								</form>
							</FlexboxGrid.Item>
						</FlexboxGrid>
					</Drawer.Body>
				</Drawer>
				<Drawer backdrop="static" open={openVloga} onClose={() => {setOpenVloga(false);setNewParameter("");}}>
					<Drawer.Header>
						<Drawer.Title>Dodaj novo vlogo</Drawer.Title>
						<Drawer.Actions>
							<Button onClick={() => {setOpenVloga(false);setNewParameter("");}}>Prekliči</Button>
						</Drawer.Actions>
					</Drawer.Header>
					<Drawer.Body>
						<FlexboxGrid justify="center">
							<FlexboxGrid.Item colspan={24}>
								<form className='rs-form rs-form-vertical rs-form-fluid' onSubmit={handleSubmitVloga}>
									<div className='rs-form-group'>
										<label id="email-address-control-label" htmlFor="language" className="rs-form-control-label">Vloga:</label>
										<div className='rs-form-control rs-form-control-wrapper'>
											<input
												className="rs-input"
												id="language"
												name="language"
												type="text"
												required
												onChange={(e) => setNewParameter({...newParameter, vloga: e.target.value})}
											/>
										</div>
										<label id="email-address-control-label" htmlFor="vlogaZenskaOblika" className="rs-form-control-label">Izpis v ženski vlogi:</label>
										<div className='rs-form-control rs-form-control-wrapper'>
											<input
												className="rs-input"
												id="vlogaZenskaOblika"
												name="vlogaZenskaOblika"
												type="text"
												required
												onChange={(e) => setNewParameter({...newParameter, izpis_zenska: e.target.value})}
											/>
										</div>
										<label id="email-address-control-label" htmlFor="vlogaMoskaOblika" className="rs-form-control-label">Izpis v moški obliki:</label>
										<div className='rs-form-control rs-form-control-wrapper'>
											<input
												className="rs-input"
												id="vlogaMoskaOblika"
												name="vlogaMoskaOblika"
												type="text"
												required
												onChange={(e) => setNewParameter({...newParameter, izpis_moski: e.target.value})}
											/>
										</div>
									</div>
									<ButtonToolbar>
										<Button appearance="primary" color="green" type="submit">Shrani</Button>
									</ButtonToolbar>
								</form>
							</FlexboxGrid.Item>
						</FlexboxGrid>
					</Drawer.Body>
				</Drawer>
			</div> }
		</div>
	)
}

export default Parameters