import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext';
import { Button, FlexboxGrid, Panel, ButtonToolbar } from 'rsuite';

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();
    const { currentUser, login, setError } = useAuth();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (currentUser) {
            navigate("/");
        }
    }, [currentUser, navigate]);

    async function handleFormSubmit(e) {
        e.preventDefault();
    
        try {
            setError("");
            setLoading(true);
            await login(email, password);
            navigate("/");
        } catch (e) {
            setError("Failed to login");
        }
        setLoading(false);
    }

    return (
        <FlexboxGrid justify="center">
            <FlexboxGrid.Item colspan={12}>
                <Panel header={<h3>Prijava uporabnika</h3>} bordered>
                    <form className='rs-form rs-form-vertical rs-form-fluid' onSubmit={handleFormSubmit}>
                        <div className='rs-form-group'>
                            <label id="email-address-control-label" htmlFor="email-address" className="rs-form-control-label">E-poštni naslov</label>
                            <div className='rs-form-control rs-form-control-wrapper'>
                                <input
                                    className="rs-input"
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='rs-form-group'>
                            <label id="password-control-label" htmlFor="password" className="rs-form-control-label">Geslo</label>
                            <div className='rs-form-control rs-form-control-wrapper'>
                                <input
                                    className="rs-input"
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                        </div>
                        <ButtonToolbar>
                            <Button appearance="primary" type="submit">Prijava</Button>
                            <Button appearance="link">Ponastavitev gesla?</Button>
                        </ButtonToolbar>
                    </form>
                </Panel>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    )
}

export default Login