import { useNavigate } from "react-router-dom";
import { Modal, Button } from 'rsuite';
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

import { useAuth } from "../../contexts/AuthContext";

export default function Logout({ modal, setModal }) {
    const navigate = useNavigate();

    const { logout, setError } = useAuth();

    async function handleLogout() {
        try {
            setError("");
            await logout();
            setModal(false);
            navigate("/login");
        } catch {
            setError("Failed to logout");
        }
    }

    return (
        <Modal open={modal} onClose={setModal}>
            <Modal.Header>
                <Modal.Title>Logging out</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ExclamationTriangleIcon
                      style={{width: "100px"}}
                    />Are you sure you want to log out ?
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleLogout} appearance="primary">
                    LogOut
                </Button>
                <Button onClick={() => setModal(false)} appearance="subtle">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}