import { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";

import { useAuth } from '../../contexts/AuthContext';

function Register() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const navigate = useNavigate();
    const { currentUser, register, setError } = useAuth();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (currentUser) {
            navigate("/");
        }
    }, [currentUser, navigate]);

    async function handleFormSubmit(e) {
        e.preventDefault();
    
        if (password !== confirmPassword) {
            return setError("Passwords do not match");
        }
        try {
            setError("");
            setLoading(true);
            await register(email, password);
            navigate("/profile");
        } catch (e) {
            setError("Failed to register");
        }
        setLoading(false);
    }

    return (
        <div>
            <h2>Register</h2>
            <form onSubmit={handleFormSubmit}>
                <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    placeholder="Email address"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autoComplete="current-password"
                    required
                    placeholder="Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button
                    type="submit"
                    className=""
                    disabled={loading}
                    >
                    Register
                </button>
                <Link
                    to="/login"
                    className=""
                >
                    Already have an account? Login
                </Link>
            </form>
        </div>
    )
}

export default Register