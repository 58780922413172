import React, { useState, useEffect } from 'react';
import {
    Panel,
    Input,
    InputGroup,
    Table,
    Button,
    DOMHelper,
    Progress,
    Checkbox,
    Stack,
    SelectPicker,
    Drawer,
    Form,
    RadioGroup,
    Radio,
    FlexboxGrid,
    List,
    InputPicker,
    CheckPicker,
    Notification,
    useToaster,
    Loader,
    ButtonToolbar,
    IconButton,
    Modal
} from 'rsuite';
import auth from "../../../config/firebase";
import SearchIcon from '@rsuite/icons/Search';
import MoreIcon from '@rsuite/icons/legacy/More';
import TrashIcon from '@rsuite/icons/Trash';
import EditIcon from '@rsuite/icons/Edit';
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
import RemindRoundIcon from '@rsuite/icons/RemindRound'
import AddNewMember from './AddNewMember';
import { NameCell, ImageCell, CheckCell, ActionCell } from './Cells.tsx';

const { Column, HeaderCell, Cell } = Table;
const { getHeight } = DOMHelper;

// const ratingList = Array.from({ length: 5 }).map((_, index) => {
//     return {
//         value: index + 1,
//         label: Array.from({ length: index + 1 })
//         .map(() => '⭐️')
//         .join('')
//     };
// });


const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);

const Members = () => {
    const [data, setData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [userPrevodi, setUserPrevodi] = useState([]);
    const [jeziki, setJeziki] = useState([]);
    const [podrocja, setPodrocja] = useState([]);
    const [vloge, setVloge] = useState([]);
    const [showDrawer, setShowDrawer] = useState(false);
    const [showDrawerMember, setShowDrawerMember] = useState(false);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [searchKeyword, setSearchKeyword] = useState('');
    const [rating, setRating] = useState(null);
    const [editedData, setEditedData] = useState(data);
    const [loading, setLoading] = useState(true);
    const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false);
    const handleOpenDeleteConfirm = () => setOpenDeleteConfirm(true);
    const handleCloseDeleteConfirm = () => setOpenDeleteConfirm(false);

    const [addingPrevodValue, setAddingPrevodValue] = useState({
        rang: '',
        iz: '',
        v: '',
        podrocja: [],
        vloge: []
    })

    const [responseData, setResponseData] = useState(null);

    const [profile, setProfile] = useState("");
    const [languages, setLanguages] = useState([
        // List of languages
    ]);
    const [fields, setFields] = useState({
        izOptions: jeziki.map((lang) => ({ id: lang.id, value: lang.jezik })),
        vOptions: jeziki.map((lang) => ({ id: lang.id, value: lang.jezik })),
        podrocjaOptions: podrocja.map((e) => ({ id: e.id, value: e.podrocje })),
        vlogeOptions: vloge.map((e) => ({ id: e.id, value: e.vloga })),
    });

    const toaster = useToaster();

	const messageSuccess = (
		<Notification type="success" header="Uspešno" closable>
			<p>Uporabnik uspešno dodan! Dobrodošel, [uporabniško ime].</p>
		</Notification>
	);
	const messageFailed = (
		<Notification type="error" header="Napaka" closable>
			<p>Prišlo je do napake. Prosimo, poskusite znova.</p>
		</Notification>
	);

    //   console.log(jeziki.map((lang) => ({ id: lang.id, value: lang.jezik })))

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevProfile) => ({
        ...prevProfile,
        [name]: value,
        }));
    };
    
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = auth.currentUser;
                const token = user && (await user.getIdToken());
        
                const payloadHeader = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                };
                const res = await fetch("https://urejevalnik.dpoint.dev/express/api/v1/clan/", payloadHeader)
                    .then(res => res.json())
                    .then((res) => { setData(res); });
                const res2 = await fetch("https://urejevalnik.dpoint.dev/express/api/v1/jezik/", payloadHeader);
                const resu2 = await res2.json();
                setJeziki(resu2);
                    // .then(res2 => res2.json())
                    // .then((res2) => { setJeziki(res2); });
                const res3 = await fetch("https://urejevalnik.dpoint.dev/express/api/v1/vloga/", payloadHeader)
                    .then(res3 => res3.json())
                    .then((res3) => { setVloge(res3); });
                const res4 = await fetch("https://urejevalnik.dpoint.dev/express/api/v1/podrocje", payloadHeader)
                    .then(res4 => res4.json())
                    .then((res4) => { setPodrocja(res4); });
                    setLoading(false);
                // setData(newData);
                // console.log(data);
                
                setLoading(false);
            } catch (e) {
                console.log(e);
            }
        };

        fetchData();
    }, []);

    const handleClick = (id) => {
        const fetchData = async () => {
            try {
                const user = auth.currentUser;
                const token = user && (await user.getIdToken());
        
                const payloadHeader = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                };
                const res = await fetch(`https://urejevalnik.dpoint.dev/express/api/v1/clan/${id}`, payloadHeader)
                    .then(res => res.json())
                    .then((res) => { setUserData(res); });
                const response = await fetch(`https://urejevalnik.dpoint.dev/express/api/v1/clan/${id}/prevod/`, payloadHeader)
                    .then(response => response.json())
                    .then((response) => { setUserPrevodi(response); });
            } catch (e) {
                console.log(e);
            }
        };
        fetchData().then(setShowDrawerMember(true));
    }

    const handleDeleteUser = async (id) => {
        setOpenDeleteConfirm(false);
        const messageSuccess = (
            <Notification type="success" header="Uspešno" closable>
                <p>Uporabnika [uporabniško ime] ste uspešno zbrisali.</p>
            </Notification>
        );

        const messageFailed = (
            <Notification type="error" header="Napaka" closable>
                <p>Prišlo je do napake. Prosimo, poskusite znova.</p>
            </Notification>
        );

        try {
			const user = auth.currentUser;
			const token = user && (await user.getIdToken());
	
			const response = await fetch(`https://urejevalnik.dpoint.dev/express/api/v1/clan/${id}`, {
				method: 'DELETE',
				headers: {
					// "Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			});
		
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			} else {
				toaster.push(messageSuccess, { placement: "topStart" });
				const updatedArray = data.filter((obj) => obj.id !== id);
    			setData(updatedArray);
                setShowDrawerMember(false);
			}
		} catch (error) {
			console.error('Error making DELETE request:', error);
			toaster.push(messageFailed, { placement: "topStart" })
		}
	}

    const handleUpdateClan = async (id) => {

        const messageSuccess = (
            <Notification type="success" header="Uspešno" closable>
                <p>Uspešno ste posodobili profil [uporabniško ime].</p>
            </Notification>
        );

        const messageFailed = (
            <Notification type="error" header="Napaka" closable>
                <p>Prišlo je do napake. Prosimo, poskusite znova.</p>
            </Notification>
        );

		// const updatedClan = data.find(x => x.id === id);

        try {
			const user = auth.currentUser;
			const token = user && (await user.getIdToken());
	
			const response = await fetch(`https://urejevalnik.dpoint.dev/express/api/v1/clan/${id}`, {
				method: 'PUT',
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(userData),
			});
		
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
		
			const jsonResponse = await response.json();
			setResponseData(jsonResponse);
			toaster.push(messageSuccess, { placement: "topStart" })
			setEdit(false)
		} catch (error) {
			console.error('Error making POST request:', error);
			toaster.push(messageFailed, { placement: "topStart" })
		}
	}

    let checked = false;
    let indeterminate = false;

    if (checkedKeys.length === data.length) {
        checked = true;
    } else if (checkedKeys.length === 0) {
        checked = false;
    } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
        indeterminate = true;
    }

    const handleCheckAll = (_value, checked) => {
        const keys = checked ? data.map(item => item.id) : [];
        setCheckedKeys(keys);
    };
    const handleCheck = (value, checked) => {
        const keys = checked ? [...checkedKeys, value] : checkedKeys.filter(item => item !== value);
        setCheckedKeys(keys);
    };

    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    };

    const filteredData = () => {
        const filtered = data.filter(item => {
            if (!item.ime.includes(searchKeyword)) {
                return false;
            }

            return true;
        });

        if (sortColumn && sortType) {
            return filtered.sort((a, b) => {
                let x = a[sortColumn];
                let y = b[sortColumn];

                if (typeof x === 'string') {
                    x = x.charCodeAt(0);
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt(0);
                }

                if (sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }
        return filtered;
    };

    const rang = [
        {
            "label": "A",
            "value": "A"
        },
        {
            "label": "B",
            "value": "B"
        },
        {
            "label": "C",
            "value": "C"
        },
        {
            "label": "D",
            "value": "D"
        },
        {
            "label": "E",
            "value": "E"
        },
        {
            "label": "F",
            "value": "F"
        },
        {
            "label": "G",
            "value": "G"
        },
        {
            "label": "H",
            "value": "H"
        },
    ]

    if (loading) {
        return <FlexboxGrid justify='center'>
        <FlexboxGrid.Item colspan={12}>
            <Loader size="lg" content="Nalagam ..." />
        </FlexboxGrid.Item>
    </FlexboxGrid>;
      }

    const jezikiForm = jeziki.map(item => ({ label: item.jezik, value: item.id }))
    const podrocjaForm = podrocja.map(item => ({ label: item.podrocje, value: item.id }))
    const vlogeForm = vloge.map(item => ({ label: item.vloga, value: item.id }))

    return (
        <div className="rs-panel-body">
            <Stack style={{marginBottom: "1rem"}} className="table-toolbar" justifyContent="space-between">
                <Button appearance="primary" onClick={() => setShowDrawer(true)}>
                    Dodaj člana
                </Button>

                <Stack spacing={6}>
                    {/* <SelectPicker
                        label="Rating"
                        data={ratingList}
                        searchable={false}
                        value={rating}
                        onChange={setRating}
                    /> */}
                    <InputGroup inside>
                        <Input placeholder="Search" value={searchKeyword} onChange={setSearchKeyword} />
                        <InputGroup.Addon>
                            <SearchIcon />
                        </InputGroup.Addon>
                    </InputGroup>
                </Stack>
            </Stack>

            <Table
                height={Math.max(getHeight(window) - 200, 400)}
                data={filteredData()}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
                virtualized
            >
                <Column width={100} align="center" fixed sortable>
                    <HeaderCell>Id</HeaderCell>
                    <Cell dataKey="id" />
                </Column>

                <Column width={100} align="center" fixed>
                    <HeaderCell>Št. izkaznice</HeaderCell>
                    <Cell dataKey="id_izkaznica" />
                </Column>

                <Column width={50} fixed>
                    <HeaderCell style={{ padding: 0 }}>
                        <div style={{ lineHeight: '40px' }}>
                            <Checkbox
                                inline
                                checked={checked}
                                indeterminate={indeterminate}
                                onChange={handleCheckAll}
                            />
                        </div>
                    </HeaderCell>
                    <CheckCell dataKey="id" checkedKeys={checkedKeys} onChange={handleCheck} />
                </Column>
                {/* <Column width={80} align="center">
                    <HeaderCell>Avatar</HeaderCell>
                    <ImageCell dataKey="avatar" />
                </Column> */}

                <Column minWidth={160} flexGrow={1} sortable>
                    <HeaderCell>Ime</HeaderCell>
                    <NameCell dataKey="ime" onClick={handleClick}/>
                </Column>

                <Column minWidth={160} flexGrow={1} sortable>
                    <HeaderCell>Priimek</HeaderCell>
                    <Cell dataKey="priimek" />
                </Column>

                {/* <Column width={230} sortable>
                    <HeaderCell>Skill Proficiency</HeaderCell>
                    <Cell style={{ padding: '10px 0' }} dataKey="progress">
                        {rowData => <Progress percent={rowData.progress} showInfo={false} />}
                    </Cell>
                </Column> */}

                {/* <Column width={100} sortable>
                    <HeaderCell>Rating</HeaderCell>
                    <Cell dataKey="rating">
                        {rowData =>
                        Array.from({ length: rowData.rating }).map((_, i) => <span key={i}>⭐️</span>)
                        }
                    </Cell>
                </Column> */}

                {/* <Column width={100} sortable>
                    <HeaderCell>Income</HeaderCell>
                    <Cell dataKey="amount">{rowData => `$${rowData.amount}`}</Cell>
                </Column> */}

                <Column width={300}>
                    <HeaderCell>Email</HeaderCell>
                    <Cell dataKey="email" />
                </Column>

                <Column width={120} >
                    <HeaderCell>
                        <MoreIcon />
                    </HeaderCell>
                    <Cell>
                        {/* <Button appearance="link" onClick={handleClick}>Več</Button> */}
                    </Cell>
                </Column>

                {/* <Column width={120}>
                    <HeaderCell>
                        <MoreIcon />
                    </HeaderCell>
                    <ActionCell dataKey="id" />
                </Column> */}
            </Table>

            <AddNewMember
                dataJeziki={jeziki}
                dataPodrocja={podrocja}
                dataVloge={vloge}
                open={showDrawer}
                onClose={() => setShowDrawer(false)}
            />
            {/* <MemberView
                data={userData}
                dataPrevodi={userPrevodi}
                open={showDrawerMember}
                jeziki={jeziki}
                podrocja={podrocja}
                vloge={vloge}
                onClose={() => setShowDrawerMember(false)}
                onChange={value => setValue(value)}
            /> */}
            <Drawer open={showDrawerMember} backdrop="static" size="lg" placement="right" onClose={() => setShowDrawerMember(false)}>
            <Drawer.Header>
                <Drawer.Title>{userData.ime} {userData.priimek}</Drawer.Title>
                <Drawer.Actions>
                    {edit ?
                        <ButtonToolbar>
                            <IconButton
                                appearance="primary"
                                color="green"
                                icon={<CheckOutlineIcon />}
                                onClick={() => handleUpdateClan(userData.id)}
                            >
                                Shrani
                            </IconButton>
                            <IconButton
                                appearance="default"
                                icon={<CloseOutlineIcon />}
                                onClick={() => setEdit(false)}
                            >
                                Prekliči
                            </IconButton>
                        </ButtonToolbar>
                        // <Button onClick={() => setEdit(false)} color="green" appearance="ghost">
                        //     Shrani
                        // </Button>
                    :
                        <ButtonToolbar>
                            <IconButton
                                appearance="primary"
                                color="green"
                                icon={<EditIcon />}
                                onClick={() => setEdit(true)}
                            >
                                Uredi
                            </IconButton>
                            <IconButton
                                appearance="primary"
                                color="red"
                                icon={<TrashIcon />}
                                onClick={handleOpenDeleteConfirm}
                            >
                                Izbriši član_ico
                            </IconButton>
                            <IconButton
                                appearance="default"
                                icon={<CloseOutlineIcon />}
                                onClick={() => setShowDrawerMember(false)}
                            >
                                Zapri
                            </IconButton>
                        </ButtonToolbar>}
                    {/* {edit ? <Button onClick={() => setEdit(false)} color="red" appearance="ghost">
                        Prekliči
                    </Button> :
                    <Button onClick={() => setShowDrawerMember(false)} appearance="subtle">
                        Zapri
                    </Button>} */}
                </Drawer.Actions>
            </Drawer.Header>
            <Modal backdrop="static" role="alertdialog" open={openDeleteConfirm} onClose={handleCloseDeleteConfirm} size="xs">
                <Modal.Body>
                    <RemindRoundIcon style={{ color: '#ffb300', fontSize: 24 }} />
                    Ali ste prepričani, da želite zbrisati uporabnika [uporabniško ime]?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleDeleteUser(userData.id)} appearance="primary">
                        Da
                    </Button>
                    <Button onClick={handleCloseDeleteConfirm} appearance="subtle">
                        Ne
                    </Button>
                </Modal.Footer>
            </Modal>
            <Drawer.Body>
                {edit ? 
                <>
                    <Form
                        fluid
                        // defaultValue={userData}
                        formValue={userData}
                        onChange={userData => setUserData(userData)}
                    >
                        <Stack justifyContent="space-between" style={{ marginBottom: 20 }}>
                            <Form.Group>
                                <Form.ControlLabel>Ime</Form.ControlLabel>
                                <Form.Control name="ime" style={{ width: 200 }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Priimek</Form.ControlLabel>
                                <Form.Control name="priimek" style={{ width: 200 }} />
                            </Form.Group>
                            <Form.Group controlId="spol" style={{ width: 200 }}>
                                <Form.ControlLabel>Spol:</Form.ControlLabel>
                                <Form.Control name="spol" accepter={RadioGroup} inline>
                                    <Radio value="zenski">Ženski</Radio>
                                    <Radio value="moski">Moški</Radio>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Številka izkaznice</Form.ControlLabel>
                                <Form.Control name="id_izkaznica" style={{ width: 200 }} />
                            </Form.Group>
                        </Stack>
                        <Stack justifyContent="space-between" style={{ marginBottom: 20 }}>
                            <Form.Group>
                                <Form.ControlLabel>Datum rojstva</Form.ControlLabel>
                                <Form.Control name="datum_rojstva" style={{ width: 200 }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Kraj rojstva</Form.ControlLabel>
                                <Form.Control name="kraj_rojstva" style={{ width: 200 }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Datum včlanitve</Form.ControlLabel>
                                <Form.Control name="datum_vclanitve" style={{ width: 200 }} />
                            </Form.Group>
                            <Form.Group controlId="objava_v_iskalniku" style={{ width: 200 }}>
                                <Form.ControlLabel>Objava v iskalniku:</Form.ControlLabel>
                                <Form.Control name="objava_v_iskalniku" accepter={RadioGroup}>
                                    <Radio value={1}>DA</Radio>
                                    <Radio value={0}>NE</Radio>
                                </Form.Control>
                            </Form.Group>
                        </Stack>
                        <Stack justifyContent="space-between" style={{ marginBottom: 20 }}>
                            <Form.Group>
                                <Form.ControlLabel>Spletna stran</Form.ControlLabel>
                                <Form.Control name="spletnastran" style={{ width: 200 }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Telefon (mobilni)</Form.ControlLabel>
                                <Form.Control name="telefonmobi" style={{ width: 200 }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Telefon (domači)</Form.ControlLabel>
                                <Form.Control name="telefondoma" style={{ width: 200 }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Telefon (službeni)</Form.ControlLabel>
                                <Form.Control name="telefonsluzba" style={{ width: 200 }} />
                            </Form.Group>
                        </Stack>
                        <Stack justifyContent="space-between" style={{ marginBottom: 20 }}>
                            <Form.Group>
                                <Form.ControlLabel>E-poštni naslov</Form.ControlLabel>
                                <Form.Control name="email" type="email" style={{ width: 200 }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Ulica</Form.ControlLabel>
                                <Form.Control name="ulica" style={{ width: 200 }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Poštna številka</Form.ControlLabel>
                                <Form.Control name="postna_stevilka" style={{ width: 200 }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Pošta</Form.ControlLabel>
                                <Form.Control name="posta" style={{ width: 200 }} />
                            </Form.Group>
                        </Stack>
                        <Stack justifyContent="space-between" style={{ marginBottom: 20 }}>
                            <Form.Group>
                                <Form.ControlLabel>Državljanstvo</Form.ControlLabel>
                                <Form.Control name="drzavljanstvo" style={{ width: 200 }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Materni jezik</Form.ControlLabel>
                                <Form.Control name="materni_jezik" style={{ width: 200 }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Osnovni poklic</Form.ControlLabel>
                                <Form.Control name="osnovni_poklic" style={{ width: 200 }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Šolska izobrazba</Form.ControlLabel>
                                <Form.Control name="solska_izobrazba" style={{ width: 200 }} />
                            </Form.Group>
                        </Stack>
                        <Stack justifyContent="space-between" style={{ marginBottom: 20 }}>
                            <Form.Group>
                                <Form.ControlLabel>Sedanja zaposlitev</Form.ControlLabel>
                                <Form.Control name="sedanja_zaposlitev" style={{ width: 200 }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Način pridobivanja znanja tujih jezikov</Form.ControlLabel>
                                <Form.Control accepter={Textarea} style={{ width: 300 }} rows={2} name="nacin_pridobivanja_znanja_tujih_jezikov"/>
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Prevajalska praksa</Form.ControlLabel>
                                <Form.Control accepter={Textarea} style={{ width: 300 }} rows={2} name="prevajalska_praksa" />
                            </Form.Group>
                        </Stack>
                    </Form>
                    <hr />
                    <h3>Prevodi</h3>
                    <div>
                        {/* {console.log(jezikiForm)}
                        {console.log(podrocjaForm)}
                        {console.log(vlogeForm)} */}

                        {/* <Form>
                            <InputPicker data={rang} style={{ width: 100 }} />
                            <InputPicker data={jezikiForm} style={{ width: 150 }} />
                            <InputPicker data={jezikiForm} style={{ width: 150 }} />
                            <CheckPicker data={podrocjaForm} style={{ width: 200 }} />
                            <CheckPicker data={vlogeForm} style={{ width: 150 }} />
                            <Button>Dodaj jezik</Button>
                        </Form> */}
                        <Form
                            formValue={addingPrevodValue}
                            onChange={addingPrevodValue => setAddingPrevodValue(addingPrevodValue)}
                        >
                            <hr />
                            <Form.Group controlId="rang">
                                <Form.ControlLabel>Rang:</Form.ControlLabel>
                                <Form.Control name="rang" accepter={InputPicker} data={rang} />
                            </Form.Group>
                            <Form.Group controlId="iz">
                                <Form.ControlLabel>Iz:</Form.ControlLabel>
                                <Form.Control name="iz" accepter={InputPicker} data={jezikiForm} />
                            </Form.Group>
                            <Form.Group controlId="v">
                                <Form.ControlLabel>V:</Form.ControlLabel>
                                <Form.Control name="v" accepter={InputPicker} data={jezikiForm} />
                            </Form.Group>
                            <Form.Group controlId="podrocja">
                                <Form.ControlLabel>Podrocje:</Form.ControlLabel>
                                <Form.Control name="podrocja" accepter={CheckPicker} data={podrocjaForm} />
                            </Form.Group>
                            <Form.Group controlId="vloge">
                                <Form.ControlLabel>Vloga:</Form.ControlLabel>
                                <Form.Control name="vloge" accepter={CheckPicker} data={vlogeForm} />
                            </Form.Group>
                            <Button appearance="primary" onClick={() => {setAddingPrevodValue({rang: '',
        iz: '',
        v: '',
        podrocja: [],
        vloge: []})}}>
                                Dodaj člana
                            </Button>
                            {/* <Button appearance="primary" onClick={() => setUserPrevodi([...userPrevodi, {
                                rang: addingPrevodValue.rang,
                                iz: addingPrevodValue.iz,
                                v: addingPrevodValue.v,
                                podrocja: addingPrevodValue.podrocja,
                                vloge: addingPrevodValue.vloge,
                                id_clan: userData.id
                                } ])}>
                                Dodaj člana
                            </Button> */}
                        </Form>                        
                    </div>
                    <div>
                        {/* <List>
                            {userPrevodi.map(item => {
                                return <List.Item key={2}>
                                    <FlexboxGrid>
                                        <FlexboxGrid.Item colspan={2}>
                                            <b>Rang:</b><br /> {item.rang}
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={5}>
                                            <b>Iz jezika:</b><br /> {item.iz}
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={5}>
                                            <b>V jezik:</b><br /> {item.v}
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={6}>
                                            <b>Strokovno področje:</b><br /> {item.podrocja.map(i_pod => {return <>{i_pod.podrocje} <br /></>})}
                                        </FlexboxGrid.Item>
                                        <FlexboxGrid.Item colspan={6}>
                                            <b>Vloga:</b><br /> {item.vloge.map(i_vlog => {return <>{i_vlog.vloga}</>})}
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </List.Item>
                            })}
                        </List> */}
                        <Table
                        autoHeight
                        rowHeight={60+userPrevodi.reduce((max, currentItem) => {
                            const currentLength = currentItem.podrocja.length;
                            return currentLength > max ? currentLength : max;
                          }, 0)*30}
                        // rowHeight={80}
                        data={userPrevodi}
                        style={{marginTop: "2rem"}}
                    >
                        <Column align="center" fixed >
                            <HeaderCell>rang</HeaderCell>
                            <Cell dataKey="rang" />
                        </Column>
                        <Column  align="center" fixed >
                            <HeaderCell>iz</HeaderCell>
                            <Cell dataKey="iz" />
                        </Column>
                        <Column  align="center" fixed >
                            <HeaderCell>v</HeaderCell>
                            <Cell dataKey="v" />
                        </Column>
                        <Column  align="center" fixed >
                            <HeaderCell>Licenca dztps</HeaderCell>
                            <Cell dataKey="licenca_DZTPS" />
                        </Column>
                        <Column  align="center" fixed flexGrow={1}>
                            <HeaderCell>Strokovno podrocje</HeaderCell>
                            <Cell > 
                                {rowData => rowData.podrocja.map((e, i) => <><span>{e.podrocje}</span><br /></>)}
                            </Cell>
                        </Column>
                        <Column align="center" fixed >
                            <HeaderCell>Vloga</HeaderCell>
                            <Cell > 
                                {rowData => rowData.vloge.map((e, i) => <><span>{e.vloga}</span><br /></>)}
                            </Cell>
                        </Column>
                    </Table>
                    </div>
                </>
                :
                <>
                    <List>
                        <List.Item>
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>Številka izkaznice:</b> {userData.id_izkaznica}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>Datum včlanitve:</b> {userData.datum_vclanitve}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        <List.Item>
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>Spol:</b> {userData.spol === "zenska" ? "Ženski" : "Moški"}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>Datum rojstva:</b> {userData.datum_rojstva}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        <List.Item>
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>Kraj rojstva:</b> {userData.kraj_rojstva}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>E-poštni naslov:</b> {userData.email}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        <List.Item>
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>Spletna stran:</b> {userData.spletnastran}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>Telefon (mobilni):</b> {userData.telefonmobi}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        <List.Item>
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>Telefon (domači):</b> {userData.telefondoma}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>Telefon (službeni):</b> {userData.telefonsluzba}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        <List.Item>
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>Objava v iskalniku:</b> {userData.objava_v_iskalniku === 1 ? "DA" : "NE"}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>Ulica:</b> {userData.ulica}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        <List.Item>
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>Poštna številka:</b> {userData.postna_stevilka}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>Pošta:</b> {userData.posta}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        <List.Item>
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>Materni jezik:</b> {userData.materni_jezik}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>Osnovni poklic:</b> {userData.osnovni_poklic}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        <List.Item>
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>Šolska izobrazba:</b> {userData.solska_izobrazba}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>Sedanja zaposlitev:</b> {userData.sedanja_zaposlitev}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        <List.Item>
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>Način pridobivanja znanja tujih jezikov:</b> {userData.nacin_pridobivanja_znanja_tujih_jezikov}
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={12}>
                                    <b>Državljanstvo:</b> {userData.drzavljanstvo}
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        <List.Item>
                            <b>Prevajalska praksa:</b> {userData.prevajalska_praksa}
                        </List.Item>
                    </List>
                    <Table
                        autoHeight
                        rowHeight={60+userPrevodi.reduce((max, currentItem) => {
                            const currentLength = currentItem.podrocja.length;
                            return currentLength > max ? currentLength : max;
                          }, 0)*30}
                        // rowHeight={80}
                        data={userPrevodi}
                        style={{marginTop: "2rem"}}
                    >
                        <Column align="center" fixed >
                            <HeaderCell>rang</HeaderCell>
                            <Cell dataKey="rang" />
                        </Column>
                        <Column  align="center" fixed >
                            <HeaderCell>iz</HeaderCell>
                            <Cell dataKey="iz" />
                        </Column>
                        <Column  align="center" fixed >
                            <HeaderCell>v</HeaderCell>
                            <Cell dataKey="v" />
                        </Column>
                        <Column  align="center" fixed >
                            <HeaderCell>Licenca dztps</HeaderCell>
                            <Cell dataKey="licenca_DZTPS" />
                        </Column>
                        <Column  align="center" fixed flexGrow={1}>
                            <HeaderCell>Strokovno podrocje</HeaderCell>
                            <Cell > 
                                {rowData => rowData.podrocja.map((e, i) => <><span>{e.podrocje}</span><br /></>)}
                            </Cell>
                        </Column>
                        <Column align="center" fixed >
                            <HeaderCell>Vloga</HeaderCell>
                            <Cell > 
                                {rowData => rowData.vloge.map((e, i) => <><span>{e.vloga}</span><br /></>)}
                            </Cell>
                        </Column>
                    </Table>
                </>
                }
            </Drawer.Body>
        </Drawer>
        </div>
    );
};

export default Members;